
import RampCard from '@/components/ramp/RampCard.vue'
import { defineComponent, ref } from 'vue'
import { getRamps } from '@/api/ramp/getRamps'
import { Ramp } from '@/types/ramp'
import AppLoadingSlot from '@/components/AppLoadingSlot.vue'
import { useRouter } from 'vue-router'
import { format } from 'date-fns'
import WashListCompletedArrivalProgress from '@/components/wash-list/WashListCompletedArrivalProgress.vue'

export default defineComponent({
  name: 'Ramp',

  components: {
    RampCard,
    WashListCompletedArrivalProgress,
    AppLoadingSlot,
  },

  setup() {
    const router = useRouter()
    const loading = ref(true)
    const ramps = ref<Ramp[]>([])
    async function fetchRamps() {
      try {
        const { data } = await getRamps()
        ramps.value = data
      } catch (error) {
        console.log(error)
        ramps.value = []
      } finally {
        loading.value = false
      }
    }

    fetchRamps()

    function goToRamp(uuid: string) {
      const today = format(new Date(), 'yyyy-MM-dd')
      router.push(`/mobile/wash-list/${uuid}/${today}`)
    }

    return {
      loading,
      ramps,
      goToRamp,
    }
  },
})
